import React from "react";
import { SectionProps } from "../../@types/Section";
import styles from "./Products.module.scss";
import Link from "next/link";
import FadeIn from "../../Components/FadeIn";
import Splitter from "../../Components/Splitter";
import Typer from "../../Components/Typer";
import RightArrowIcon from "../../Components/assets/arrow--right.svg";
import Image from "next/image";
import { getStrapiMedia } from "../../strapi/media";

interface Props extends SectionProps {
  data: any;
}

const HomeProducts = React.forwardRef<HTMLElement, Props>(function HomeProducts(
  props,
  ref
) {
  const [isHovering, setIsHovering] = React.useState(false);
  const [elRefs] = React.useState<React.RefObject<HTMLDivElement>[]>(
    props.data && props.data.length
      ? props.data.map(() => React.createRef())
      : []
  );
  const [playState, setPlayState] = React.useState(0);

  const videoEndCallback = () => {
    let val = 0;
    if (!isHovering) {
      val = playState + 1;
    }
    setPlayState(val);
  };

  const handleMouseLeave = (i: number) => {
    setIsHovering(false);
    elRefs.forEach((elRef, index) => {
      const videoEl = elRef.current?.getElementsByTagName("video");
      if (videoEl && i === index) {
        videoEl[0].loop = false;
      }
    });
  };
  const handleMouseEnter = (i: number) => {
    setIsHovering(true);
    elRefs.forEach((elRef, index) => {
      const videoEl = elRef.current?.getElementsByTagName("video");
      if (videoEl) {
        if (i === index) {
          videoEl[0].loop = true;
          if (i !== playState) {
            videoEl[0].pause();
            videoEl[0].currentTime = 0;
            setPlayState(index);
          }
        } else {
          videoEl[0].pause();
          videoEl[0].currentTime = 0;
        }
      }
    });
  };

  React.useEffect(() => {
    if (elRefs.length - 1 < playState) {
      setPlayState(0);
    } else {
      const videoEl = elRefs[playState].current?.getElementsByTagName("video");
      if (videoEl) {
        videoEl[0].play();
      }
    }
  }, [playState, elRefs]);

  const listItems =
    props.data && props.data.length
      ? props.data.map((product: any, index: number) => {
          const videoSrc = product.Video
            ? product.Video.data
              ? getStrapiMedia(product.Video)
              : ""
            : "";
          const thumbnailSrc = product.Thumbnail
            ? product.Thumbnail.data
              ? getStrapiMedia(product.Thumbnail)
              : ""
            : "";
          if (
            thumbnailSrc &&
            thumbnailSrc &&
            product.Link &&
            product.Caption &&
            product.Content
          ) {
            return (
              <li key={`solution-${index}`}>
                <Link href={product.Link} className={styles["story-content"]}>
                  <div
                    className={`${styles["video-container"]} ${
                      playState === index ? ` ${styles["active"]}` : ""
                    }`}
                    ref={elRefs[index]}
                    onTouchStart={() => handleMouseEnter(index)}
                    onTouchEnd={() => handleMouseLeave(index)}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                  >
                    <div className={`${styles["video-thumbnail"]}`}>
                      <Image
                        width={900}
                        height={616}
                        src={thumbnailSrc}
                        alt={
                          product.Thumbnail.data.attributes.caption
                            ? product.Thumbnail.data.attributes.caption
                            : "Thumbnail for video"
                        }
                      />
                    </div>
                    <video
                      muted
                      controls={false}
                      playsInline={true}
                      onEnded={() => videoEndCallback()}
                    >
                      <source src={videoSrc} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className={styles["description"]}>
                    <div>
                      <h4>{product.Caption}</h4>
                      <p>{product.Content}</p>
                    </div>
                    <div className="pseudo-icon-button">
                      <RightArrowIcon />
                    </div>
                  </div>
                </Link>
              </li>
            );
          }
        })
      : [];

  return (
    <section id="products" ref={ref}>
      <div className="content-container">
        <Splitter
          heroMargin
          vizTrigger
          isThick
          text={String(props.sectionNo).padStart(2, "0")}
        />
      </div>
      <div className="content-container padded-left">
        <div className="_2-col-titled">
          <div className="col">
            <h3 className="h2">
              <Typer vizTrigger text="Products" />
            </h3>
          </div>
          {listItems.length ? (
            <div className="col">
              <FadeIn>
                <>
                  <ul
                    className={`${styles["solutions-list"]}${
                      listItems.length % 2 !== 0
                        ? ` ${styles["solutions-list-3col"]}`
                        : ""
                    }`}
                  >
                    {listItems}
                  </ul>
                </>
              </FadeIn>
            </div>
          ) : (
            <div className="col empty" />
          )}
        </div>
      </div>
    </section>
  );
});
export default HomeProducts;
